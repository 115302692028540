<template>
  <div id="app">
    <router-view v-if="isShow"></router-view>
    <!--<lightMusic class="light-music" ref="drag"/>-->
  </div>
</template>

<script>
import { mapMutations,mapState } from 'vuex'

import {banBackSpace} from './utils/preventBackspace.js'
import lightMusic from "@/views/study/light_music";

export default {
  name: 'App',
    provide() {
      return {
          reload: this.reload
      }
    },
  components: {
      lightMusic
  },
  created(){
    //刷新页面重新初始化初始化用户信息
    if(!this.user.token){
        this.initUser();
    }
  },
  computed:{
    ...mapState({
        token: state=>state.user.token,
        user: state=>state.user.user
    })
  },
    data() {
      return {
          isShow: true
      }
    },
  methods: {
      ...mapMutations(['initUser']),
      reload() {
          this.isShow = false
          this.$nextTick(() => {
              this.isShow = true
          })
          console.log('刷新页面')
      }
  },
  mounted(){
      document.onkeypress=banBackSpace
      document.onkeydown=banBackSpace
  }
}
</script>

<style>
 html,body,#app{
  width: 100%;
  height: 100%;
  font-family: Arial,serif;
  }
</style>
