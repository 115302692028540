/**
 * 规则：
 * 一、例如：index/index，shop/index以index结尾的，path和name默认去除index
 * 二、例如：shop/list，默认生成name为shop_list（如果结尾为index，例如shop/index则是shop）
 * 三、填写后不会自动生成
 * **/
let routes = [
	{
		path:'/',
		name:'layout',
		redirect:{name:'index'},
		component:'layout',
		children:[
			{
				meta:{title:'首页'},
				component:'index/index',
				children:[
					{
						meta:{title:'单词学习'},
						component:'index/study'
					}
				]
			}
		]
	},
	{
		meta:{title:'登录页'},
		component:'login/index'
	},
    {
        meta:{title: '学习页'},
        component: 'study/index'
    },
	{
		meta: { title: '学习中心' },
		component: 'study/test_center'
	},
	{
		meta: { title: '学习中心' },
		component: 'study/study_report'
	},
	{
		meta: { title: '用户中心' },
		component: 'study/user_center'
	},
	{
		meta: { title: '我的错词' },
		component: 'study/ai_word/ai_word_wrong'
	},
	{
		path: '/papers',
		meta: { title: '首页' },
		component: 'papers/index',
		redirect: '/papers/test',
		children: [
			{
				path: 'test',
				meta: { title: '考试首页' },
				component: 'papers/test'
			},
			{
				path: 'paper_runking',
				meta: { title: '排行榜' },
				component: 'papers/runking'
			},
			{
				path: 'spacial',
				meta: { title: '专项训练' },
				component: 'papers/spacial'
			}
		]
	},
	{
		meta: { title: '我的错题' },
		component: 'papers/paper_wrong'
	},
	{
		meta: { title: '学习报告' },
		component: 'papers/paper_report'
	},
	{
		meta: { title: '海量金题' },
		component: 'papers/test_papers'
	},
	{
		meta: { title: '答案解析' },
		component: 'papers/paper_analysis'
	},
	// {
	// 	meta: { title: '排行榜' },
	// 	component: 'papers/paper_list'
	// },
	{
		path:'*',
		redirect:{name:'index'},
	}
]

// 获取路由信息方法
let getRoutes = function(){
	// 自动生成路由
	createRoute(routes)
	//console.log(routes);
	return routes
}

// 自动生成路由
function createRoute(arr){
	for (let i = 0; i < arr.length; i++) {
		if (!arr[i].component) return
		// 去除index
		let val = getValue(arr[i].component)
		// 生成name
		arr[i].name = arr[i].name || val.replace(/\//g,'_')
		// 生成path
		arr[i].path = arr[i].path || `/${val}`
		// 自动生成component
		let componentFun = import(`../../views/${arr[i].component}.vue`)
		arr[i].component = ()=>componentFun
		if (arr[i].children && arr[i].children.length > 0) {
			createRoute(arr[i].children)
		}
	}
}

// 去除index
function getValue(str) {
	// str = login/index
	// 获取最后一个/的索引
	let index = str.lastIndexOf('/')
	// 获取最后一个/后面的值
	let val = str.substring(index+1,str.length)
	// 判断是不是index结尾
	if (val === 'index') {
		return str.substring(index,-1)
	}
	return str
}

export default getRoutes()
