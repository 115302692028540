<template>
    <div class="music" draggable="true" ref="music">
        <div class="music-container" ref="mCon">
            <div class="music-cover" ref="mCov"></div>
            <audio ref="audio" :autoplay="isAutoplay">
                <source
                    :src="songList[songIndex].src"
                    ref="source"
                    type="audio/mpeg"
                />
                您的浏览器不支持 audio 元素。
            </audio>
            <div class="music-container__box" ref="box">
                <img
                    ref="img"
                    src="~@/assets/images/music/song_img.jpg"
                    alt=""
                    :class="{ animations: !isPlay }"
                />
                <div class="music-container__box-cover" ref="cover">
                    <i class="fa fa-step-backward" @click="prevItem"></i>
                    <i
                        class="fa fa-play"
                        @click="isPlay ? playItem() : stopItem()"
                        :class="[isPlay ? 'fa-play' : 'fa-pause']"
                    ></i>
                    <i class="fa fa-step-forward" @click="nextItem"></i>
                    <i
                        class="iconfont icon-hengshupingqiehuan-01"
                        @click="simplify"
                    ></i>
                    <div
                        class="play-volume"
                        @click="volumeControls = !volumeControls"
                    >
                        <el-slider
                            v-model="volumeNum"
                            vertical
                            height="100px"
                            :max="1"
                            :min="0"
                            :step="0.1"
                            @input="volumeNumber"
                            :show-tooltip="false"
                            v-show="this.volumeControls"
                        >
                        </el-slider>
                        <i class="fa fa-volume" ref="fa"></i>
                    </div>
                </div>
            </div>
            <img
                class="music-container-rocker"
                ref="rocker"
                src="~@/assets/images/music/rocker.png"
                alt=""
            />
            <div class="play-choose" ref="pChoose">
                <div
                    class="play-volume"
                    @click="volumeControls = !volumeControls"
                >
                    <el-slider
                        v-model="volumeNum"
                        vertical
                        height="100px"
                        :max="1"
                        :min="0"
                        :step="0.1"
                        @input="volumeNumber"
                        :show-tooltip="false"
                        v-show="this.volumeControls"
                    >
                    </el-slider>
                    <i class="fa" ref="fa"></i>
                </div>
                <i
                    class="iconfont icon-suijibofang"
                    @click="isMode = !isMode"
                    ref="rand"
                ></i>
                <ul class="play-choose--mode" v-show="isMode">
                    <template v-for="(item, index) in modeList">
                        <li @click="playTypeA(index)" :key="index">
                            <img :src="item.img" :alt="item.text" />
                            <span>{{ item.text }}</span>
                        </li>
                    </template>
                </ul>
                <i class="iconfont icon-shangyishou" @click="prevItem"></i>
                <i
                    @click="isPlay ? playItem() : stopItem()"
                    :class="[isPlay ? 'play' : 'paused']"
                ></i>
                <i class="iconfont icon-xiayishou1" @click="nextItem"></i>
                <i class="iconfont icon-hengshupingqiehuan-01" @click="simplify"></i>
                <i class="iconfont icon-pajian_gequliebiao_" @click="songLists"></i>
            </div>
        </div>
        <div class="music-menu" ref="order">
            <ul class="music-menu__list" ref="menu">
                <li
                    class="list-item"
                    ref="listItem"
                    v-for="(item, index) in songList"
                    :key="index"
                    @click="menuChange(item, index)"
                >
                    <span>{{ index + 1 }}. </span>
                    <span>{{ item.title }}</span> -
                    <span>{{ item.author }}</span>
                    <span style="font-size: 0">{{ item.src }}</span>
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
import { mapMutations, mapState } from "vuex";
import $ from "jquery";

export default {
    name: "light_music",
    data() {
        return {
            volumeControls: false,
            simpleBool: true,
            volumeNum: 1,
            isPlay: true,
            isMode: false,
            songIndex: 0,
            audio: "",
            playType: 0, //0,1,2,3,4
            songShow: false,
            bgImage: {
                src: require("../../assets/images/music/trans.png"),
                openSrc: require("../../assets/images/music/song_bg.jpg"),
            },
            isAutoplay: true,
            modeList: [
                {
                    img: require("../../assets/images/music/play_mode.png"),
                    text: "随机播放",
                },
                {
                    img: require("../../assets/images/music/simple_circle.png"),
                    text: "单曲循环",
                },
                {
                    img: require("../../assets/images/music/list_circle.png"),
                    text: "列表循环",
                },
            ],
            songList: [
                {
                    id: "36",
                    title: "孤勇者",
                    author: "陈奕迅",
                    src: "http://res.xmy.xyz/audio/mp3/陈奕迅 - 孤勇者.mp3",
                },{
                    id: '1',
                    title: '麦浪',
                    author: 'yihuik苡慧',
                    src: 'http://res.xmy.xyz/audio/mp3/yihuik苡慧 - 麦浪.mp3'
                },
                {
                    id: '2',
                    title: '天下',
                    author: '等什么君(邓寓君)',
                    src: 'http://res.xmy.xyz/audio/mp3/等什么君(邓寓君) - 天下.mp3'
                },
                {
                    id: '3',
                    title: '斑马斑马',
                    author: '刘大壮',
                    src: 'http://res.xmy.xyz/audio/mp3/刘大壮 - 斑马斑马.mp3'
                },
                {
                  id: '4',
                    title: '三号线 (吉他版)',
                    author: '刘大壮',
                    src: 'http://res.xmy.xyz/audio/mp3/刘大壮 - 三号线 (吉他版).mp3'
                },{
                  id: '5',
                    title: '天使的翅膀',
                    author: '刘大壮',
                    src: 'http://res.xmy.xyz/audio/mp3/刘大壮 - 天使的翅膀.mp3'
                },
                {
                    id: "6",
                    title: "我不是你的宋冬野",
                    author: "刘大壮",
                    src: "http://res.xmy.xyz/audio/mp3/刘大壮 - 我不是你的宋冬野.mp3",
                },
                {
                    id: "7",
                    title: "刘大壮",
                    author: "洋葱",
                    src: "http://res.xmy.xyz/audio/mp3/刘大壮 - 洋葱.mp3",
                },
                {
                    id: "8",
                    title: '秋天不回来',
                    author: '王强',
                    src: 'http://res.xmy.xyz/audio/mp3/王强 - 秋天不回来.mp3'
                },
                {
                    id: "9",
                    title: "红蜻蜓",
                    author: "亚洲天使童声合唱团",
                    src: "http://res.xmy.xyz/audio/mp3/亚洲天使童声合唱团 - 红蜻蜓.mp3",
                },
                {
                    id: "10",
                    title: "Love You Like A Love Song (Live)",
                    author: "Selena Gomez",
                    src: "http://res.xmy.xyz/audio/mp3/Selena Gomez - Love You Like A Love Song (Live).mp3",
                }
            ],
        };
    },
    created() {
        let isPlays = this.audioPlay;
        if (isPlays != null) {
            this.isAutoplay = isPlays === "true" || isPlays === true;
        }
    },
    mounted() {
        let audio = this.$refs.audio;
        audio.addEventListener("play", () => {
            this.volumeNum = 0.3;
        });
        if (audio.played) {
            // this.isPlay = false;
            this.randPlay();
            audio.pause();
        }
    },
    updated() {
        let listItem = this.$refs.listItem;
        for (let i = 0; i < listItem.length; i++) {
            listItem[i].style.backgroundColor = "#fff";
        }
        listItem[this.songIndex].style.backgroundColor = "#F2F4CF";
    },
    computed: {
        ...mapState({
            audioPlay: (state) => state.user.audioPlay,
        }),
    },
    methods: {
        volumeNumber() {
            let audio = this.$refs.audio;
            audio.volume = this.volumeNum;
            if (this.volumeNum <= 1 && this.volumeNum > 0.5) {
                $(".play-volume i")
                    .removeClass("fa-volume-down")
                    .addClass("fa-volume-up");
            } else if (this.volumeNum <= 0.5 && this.volumeNum > 0) {
                $(".play-volume i")
                    .removeClass("fa-volume-up")
                    .removeClass("fa-volume-off")
                    .addClass("fa-volume-down");
            } else {
                $(".play-volume i")
                    .removeClass("fa-volume-up")
                    .removeClass("fa-volume-down")
                    .addClass("fa-volume-off");
            }
        },
        playTypeA(param) {
            let that = this;
            that.playType = param;
            if (that.playType === 0) {
                that.randPlay();
                this.$refs.rand.classList.remove("icon-danquxunhuan");
                this.$refs.rand.classList.remove("icon-danquxunhuan11");
                this.$refs.rand.classList.add("icon-suijibofang");
            } else if (that.playType === 1) {
                that.singlePlay();
                this.$refs.rand.classList.remove("icon-suijibofang");
                this.$refs.rand.classList.remove("icon-danquxunhuan11");
                this.$refs.rand.classList.add("icon-danquxunhuan");
            } else if (that.playType === 2) {
                that.listCircle();
                this.$refs.rand.classList.remove("icon-suijibofang");
                this.$refs.rand.classList.remove("icon-danquxunhuan");
                this.$refs.rand.classList.add("icon-danquxunhuan11");
            }
        },
        // 播放
        playItem() {
            let that = this;
            that.audio = this.$refs.audio;
            that.audio.addEventListener("ended", function () {
                that.audio.load();
                that.songIndex++;
                if (this.songIndex > that.songList.length - 1) {
                    this.songIndex = 0;
                }
                that.audio.play();
            });
            that.audio.play();
            that.audio.addEventListener("pause", function () {
                that.isPlay = true;
            });
            that.audio.addEventListener("play", function () {
                that.isPlay = false;
            });
            this.isPlay = false;
        },
        // 停止播放
        stopItem() {
            this.isPlay = true;
            this.$refs.audio.pause();
        },
        // 随机播放
        randPlay() {
            let audio = this.$refs.audio;
            this.songIndex = this.rand(0, this.songList.length - 1);
            // if (this.songIndex !== 0 || this.songIndex === 0) {
            //     this.playItem();
            // }
            audio.load();
            audio.play();
            audio.addEventListener("ended", () => {
                this.songIndex = this.rand(0, this.songList.length - 1);
                audio.load();
                audio.play();
            });
            //console.log(this.songIndex);
        },
        // 单曲循环
        singlePlay() {
            let index = this.songIndex;
            this.isPlay = false;
            this.songIndex = index;
            //console.log(this.songIndex);
            this.$refs.source.src = this.songList[this.songIndex].src;
            this.$refs.audio.playbackRate = 5;
            this.$refs.audio.play();
        },
        // 列表循环
        listCircle() {
            this.playItem();
            this.isPlay = false;
        },
        // 上一首
        prevItem() {
            this.$refs.audio.load();
            this.songIndex--;
            if (this.songIndex < 0) {
                this.songIndex = this.songList.length - 1;
            }
            this.playItem();
        },
        // 下一首
        nextItem() {
            this.isPlay = false;
            this.$refs.audio.load();
            this.songIndex++;
            this.$refs.audio.play();
            //console.log(this.songIndex, this.$refs.source.src);
            if (this.songIndex > this.songList.length - 1) {
                this.songIndex = 0;
            }
        },
        // 随机数
        rand(m, n) {
            return Math.floor(Math.random() * (n - m + 1) + m);
        },
        songLists() {
            if (this.songShow === 1) {
                this.$refs.order.classList.remove("height-change");
                this.$refs.menu.classList.remove("height-change");
                this.songShow = 0;
            } else {
                this.$refs.order.classList.add("height-change");
                this.$refs.menu.classList.add("height-change");
                this.songShow = 1;
            }
        },
        menuChange(item, index) {
            let audio = this.$refs.audio;
            audio.load();
            this.songIndex = index;
            //console.log(this.songList.length);
            audio.addEventListener("ended", () => {
                audio.load();
                this.songIndex++;
                if (this.songIndex > this.songList.length - 1) {
                    this.songIndex = 0;
                }
                audio.play();
            });
            audio.play();
            this.isPlay = false;
        },
        // 播放器简化
        simplify() {
            //console.log(this.simpleBool);
            this.$refs.order.classList.remove("height-change");
            this.$refs.menu.classList.remove("height-change");
            let music = this.$refs.music;
            let cover = this.$refs.cover;
            let box = this.$refs.box;
            let mCov = this.$refs.mCov;
            let img = this.$refs.img;
            let rocker = this.$refs.rocker;
            let pChoose = this.$refs.pChoose;
            let fa = this.$refs.fa;
            if (this.simpleBool) {
                //console.log("缩小");
                cover.style.display = "block";
                pChoose.style.opacity = "0";
                pChoose.style.width = "0";
                pChoose.classList.remove("play-m");
                fa.style.fontSize = "0";
                box.classList.add("rotation");
                rocker.style.width = "0";
                music.classList.remove("open");
                music.classList.add("resize");
                rocker.style.opacity = "0";
                music.addEventListener("animationend", () => {
                    mCov.style.background = "url(" + this.bgImage.src + ")";
                    box.classList.remove("rotation");
                    box.classList.add("bigger");
                    box.classList.remove("small");
                    img.classList.add("imgBigger");
                    img.classList.remove("imgSmall");
                    fa.style.fontSize = "0";
                    cover.addEventListener("mouseover", () => {
                        cover.classList.add("trans");
                    });
                    cover.addEventListener("mouseleave", () => {
                        cover.classList.remove("trans");
                    });
                    pChoose.style.visibility = "hidden";
                });
                //         .on('mouseover', ()=>{
                //     $('.light-music').css('right', '-35px');
                // }).on('mouseout', ()=>{
                //     $('.light-music').css('right', '-110px');
                // })
                this.simpleBool = false;
            } else {
                //console.log("展开");
                cover.style.display = "none";
                box.classList.add("reRotation");
                // pChoose.style.display = 'block';
                music.classList.remove("resize");
                music.classList.add("open");
                img.classList.remove("imgBigger");
                box.classList.remove("bigger");
                pChoose.style.opacity = "0";
                mCov.style.background = "url(" + this.bgImage.openSrc + ")";
                music.addEventListener("animationend", () => {
                    box.classList.remove("reRotation");
                    mCov.style.background = "url(" + this.bgImage.openSrc + ")";
                    // box.classList.add('small');
                    // img.classList.add('imgSmall');
                    img.classList.remove("imgBigger");
                    box.classList.remove("bigger");
                    pChoose.style.opacity = "1";
                    rocker.style.opacity = "1";
                    fa.style.fontSize = "23px";
                    pChoose.style.visibility = "visible";
                });
                // pChoose.style.width = '60%';
                fa.style.fontSize = "0";
                pChoose.classList.add("play-m");
                rocker.style.width = "85px";
                this.simpleBool = true;
            }
        },
    },
    destroyed() {
        this.$store.commit("setAudioPlay");
    },
};
</script>

<style lang="scss" scoped>
@keyframes rotation {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

@keyframes resize {
    from {
        width: 350px;
    }
    to {
        width: 200px;
    }
}

@keyframes open {
    from {
        width: 200px;
    }
    to {
        width: 350px;
    }
}

@keyframes bigger {
    from {
        width: 100px;
        height: 100px;
    }
    to {
        width: 130px;
        height: 130px;
    }
}

@keyframes small {
    from {
        width: 100px;
        height: 100px;
    }
    to {
        width: 100px;
        height: 100px;
    }
}

@keyframes imgBigger {
    from {
        width: 70px;
        height: 70px;
    }
    to {
        width: 86px;
        height: 86px;
    }
}

@keyframes imgSmall {
    from {
        width: 70px;
        height: 70px;
    }
    to {
        width: 70px;
        height: 70px;
    }
}

@keyframes trans {
    from {
        opacity: 0;
    }
    to {
        opacity: 0.5;
    }
}

@keyframes playMode {
    from {
        width: 0;
    }
    to {
        width: 60%;
    }
}

@mixin borders($index, $bottom, $left, $width, $color) {
    content: "";
    z-index: $index;
    position: absolute;
    bottom: $bottom;
    left: $left;
    width: 0;
    height: 0;
    border-top: $width solid $color;
    border-right: $width solid transparent;
    border-bottom: $width solid transparent;
    border-left: $width solid transparent;
}

.resize {
    animation: resize 1s linear forwards;
}

.open {
    animation: open 1s linear forwards;
}

.music {
    z-index: 2700;
    width: 350px;
    border-radius: 8px;

    &-container {
        position: relative;
        display: flex;
        align-items: center;
        padding-top: 0.5px;
        height: 80px;
        border-radius: 8px;

        .music-cover {
            z-index: -2;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 80px;
            background: url("~@/assets/images/music/song_bg.jpg") no-repeat;
            background-size: 100% 100%;
            border-radius: 8px;
        }

        .bigger {
            animation: bigger 0.2s linear forwards;
        }

        .small {
            animation: small 0.2s linear forwards;
        }

        .rotation {
            animation: rotation 2s linear infinite;
        }

        .reRotation {
            animation: rotation 2s linear infinite reverse;
        }

        &__box {
            display: flex;
            justify-content: center;
            align-items: center;
            position: relative;
            margin: -80px 0 0 5px;
            width: 100px;
            height: 100px;
            background: url("~@/assets/images/music/img_bg.png") no-repeat;
            background-size: 100% 100%;
            border-radius: 50%;

            .imgBigger {
                animation: imgBigger 0.2s linear forwards;
            }

            .imgSmall {
                animation: imgSmall 0.2s linear forwards;
            }

            img {
                width: 70px;
                height: 70px;
                border-radius: 50%;
            }

            .trans {
                animation: trans 0.2s linear forwards;
            }

            &-cover {
                z-index: 1;
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                border-radius: 50%;
                background-color: #111;
                opacity: 0;

                .fa {
                    position: absolute;
                    color: #cbcbcb;
                    font-size: 24px;
                    cursor: pointer;

                    &:hover {
                        color: #fff;
                    }
                }

                .iconfont {
                    position: absolute;
                    bottom: 5px;
                    left: 50%;
                    transform: translate(-50%, 0);
                    display: inline-block;
                    width: 24px;
                    height: 24px;
                    font-size: 23px;
                    color: #fff;
                    cursor: pointer;
                }

                .fa-step-backward {
                    left: 10px;
                    top: 50%;
                    transform: translate(0, -50%);
                }

                .fa-step-forward {
                    right: 10px;
                    top: 50%;
                    transform: translate(0, -50%);
                }

                .fa-pause,
                .fa-play {
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                }

                .play-volume {
                    .fa-volume {
                        top: 8px;
                        left: 50%;
                        transform: translate(-50%, 0);
                        width: 23px;
                        height: 23px;
                        font-size: 23px;
                    }

                    .el-slider {
                        left: 55px;
                        bottom: 90px;
                        opacity: 1;
                    }
                }
            }

            .animations {
                animation: rotation 5s linear infinite;
            }
        }

        &-rocker {
            position: absolute;
            top: 32px;
            left: 76px;
            width: 85px;
            height: 38px;
        }

        .song-close {
            position: absolute;
            top: 5px;
            right: 5px;
            width: 30px;
            height: 30px;

            img {
                width: 12px;
                height: 12px;
                cursor: pointer;
            }
        }

        .play-m {
            animation: playMode 1s linear forwards;
        }

        .play-choose {
            position: relative;
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-left: 8px;
            width: 60%;
            height: 38px;

            & > .iconfont:first-of-type {
                font-size: 20px;
            }
            .play-volume {
                position: relative;

                .el-slider {
                    position: absolute;
                    bottom: 32px;
                    left: -5px;
                }

                .fa {
                    width: 23px;
                    height: 23px;
                    color: #fff;
                    font-size: 23px;
                    opacity: 0.6;
                }
            }

            i {
                display: inline-block;
                cursor: pointer;
            }

            &--mode {
                position: absolute;
                top: -165px;
                left: -26px;
                text-align: center;
                background-color: #fff;
                box-shadow: 0 0 10px #bbb;
                border-radius: 8px;
                transition: all 0.5s;

                &::after {
                    @include borders(1, -30px, 50px, 15px, #ccc);
                }

                &::before {
                    @include borders(2, -28px, 51px, 14px, #fff);
                }

                li {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    width: 128px;
                    line-height: 49px;
                    border-bottom: 1px solid #ddd;
                    cursor: pointer;
                    transition: all 0.3s;

                    &:hover {
                        background-color: #efefef;
                    }

                    &:last-child {
                        border-bottom: none;
                        border-bottom-left-radius: 8px;
                        border-bottom-right-radius: 8px;
                    }

                    img {
                        margin-right: 10px;
                        width: 24px;
                        height: 20px;
                    }
                }
            }

            .fa {
                color: #fff;
                font-size: 20px;
                opacity: 0.6;
            }

            .iconfont,
            .icon-hengshupingqiehuan-01 {
                color: #fff;
                font-size: 24px;
                font-weight: bold;
                opacity: 0.6;
            }

            .icon-pajian_gequliebiao_ {
                font-size: 26px;
            }

            .play,
            .paused {
                width: 30px;
                height: 30px;
                vertical-align: -5px;
            }

            .play {
                background: url("~@/assets/images/music/play.png");
                background-size: 100% 100%;
            }

            .paused {
                background: url("~@/assets/images/music/paused.png");
                background-size: 100% 100%;
            }

            .narrow {
                background: url("~@/assets/images/music/narrow.png");
                background-size: 100% 100%;
            }
        }
    }

    .height-change {
        height: 180px;
    }

    &-menu {
        height: 0;
        border-bottom-left-radius: 8px;
        border-bottom-right-radius: 8px;
        transition: height 0.3s;

        &__list {
            overflow-y: scroll;
            height: 0;
            background-color: #fff;
            transition: height 0.3s;

            &::-webkit-scrollbar {
                width: 4px;
            }

            &::-webkit-scrollbar-thumb {
                border-radius: 10px;
                -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
                background: rgba(0, 0, 0, 0.2);
            }

            &::-webkit-scrollbar-track {
                -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
                border-radius: 0;
                background: rgba(0, 0, 0, 0.1);
            }

            .list-item {
                padding-left: 20px;
                width: 346px;
                height: 45px;
                line-height: 45px;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                border-bottom: 1px solid #efefef;
                cursor: pointer;

                &:hover {
                    background-color: #f2f2f2;
                }

                &:last-child {
                    border-bottom: none;
                }

                span:first-child {
                    padding-right: 20px;
                }

                span:nth-child(2) {
                    padding-right: 10px;
                }

                span:last-child {
                    padding-left: 10px;
                }
            }
        }
    }
}
</style>
