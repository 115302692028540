export default {
	state:{
		user:{},
		token:false,
		audioPlay:false,
		loginCount:0,
		noticeCount:0,
		errorWordCount:0
	},
	getters:{
		
	},
	mutations:{
		// 初始化用户信息
		initUser(state){
			let user = window.sessionStorage.getItem('user');
			let token = window.sessionStorage.getItem('token');
			//console.log("initUser");
			//console.log(user);
			if(user && token){
				state.user = JSON.parse(user)
				state.token = token;

				let noticeCount = window.sessionStorage.getItem('noticeCount_'+ state.user.id);
				//console.log('noticeCount',noticeCount)
				//console.log("initUser---audioPlay",loginCount);
				if(noticeCount!=null){
					state.noticeCount = noticeCount;
				}

				let errorWordCount = window.sessionStorage.getItem('errorWordCount_'+ state.user.id);
				//console.log('noticeCount',noticeCount)
				//console.log("initUser---audioPlay",loginCount);
				if(errorWordCount!=null){
					state.errorWordCount = errorWordCount;
				}
			}

			let audioPlay = window.sessionStorage.getItem('audioPlay');
			//console.log("initUser---audioPlay",audioPlay);
			if(audioPlay!=null){
				state.audioPlay = audioPlay;
			}

			let loginCount = window.sessionStorage.getItem('loginCount');
			//console.log("initUser---audioPlay",loginCount);
			if(loginCount!=null){
				state.loginCount = loginCount;
			}

		},
		// 登录
		login(state,user){
			// 保存登录状态
			state.user = user.user
			state.token = user.token
			// 存储到本地存储
			window.sessionStorage.setItem('user',JSON.stringify(state.user))
			window.sessionStorage.setItem('token',state.token);
			//window.localStorage.setItem('audioPlay',state.audioPlay)
		},//更新token
		refreshToken(state,token){
			// 存储到本地存储
			state.token = token;
			window.sessionStorage.setItem('token',state.token);
		},//更新user
		setUser(state,user){
			// 保存登录状态
			state.user = user
			// 存储到本地存储
			window.sessionStorage.setItem('user',JSON.stringify(state.user));
		},
		setAudioPlay(state){
			if(state.audioPlay == "true" || state.audioPlay == true){
				state.audioPlay = false;
			}
		},
		setLoginCount(state){
			state.loginCount = state.loginCount + 1;
			window.sessionStorage.setItem('loginCount',state.loginCount);
		},
		setNoticeCount(state,uid){
			state.noticeCount = parseInt(state.noticeCount) + 1;
			//console.log(state.noticeCount+"===uid="+uid);
			window.sessionStorage.setItem('noticeCount_'+uid,state.noticeCount);
		},
		setErrerWordCount(state,data){
			state.errorWordCount = parseInt(data.errorWordCount);
			console.log(state.errorWordCount+"===setErrerWordCount="+data.uid);
			window.sessionStorage.setItem('errorWordCount_'+data.uid,state.errorWordCount);
		},
		// 退出登录
		logout(state){
			// 清除状态
			state.user = {}
			state.token = false
			state.noticeCount = 0
			// 清除本地存储
			window.sessionStorage.clear();
		}
	},
	actions:{
		
	}
}