export default {
	state:{
        count: 1
	},
	getters:{
	},
	mutations:{		
        increment (state) {
            state.count++;
        }
	},
	actions:{		
	}
}