function imgError (event) {
    console.log("imgError");
    let img = event.srcElement;
    img.src = require('@/assets/images/default/lucency.png');
    img.onerror = null; //防止闪图
}

function nianjiLevel(user,course,studyType=0){
    let nianji_arr = [1,2];//一，二年级
    let nianji_level = 0 ;
    if(parseInt(studyType)==2 || parseInt(studyType) == 3){//复习，我的错词
      if(nianji_arr.indexOf(parseInt(user.nianji_id))>=0){
          nianji_level = 1;
      }else{
          nianji_level = 0;
      }
    }else{
      if(nianji_arr.indexOf(parseInt(course.nianji_id))>=0){
          nianji_level = 1;
      }else{
          nianji_level = 0;
      }
    }
    return nianji_level;
}

export {imgError,nianjiLevel};


export default{
    delArrByVal:function(arrylist , val) {
        for(var i = 0; i < arrylist.length; i++) {
            if(arrylist [i] == val) {
                arrylist .splice(i, 1);
                break;
            }
        }
    },
    getDisNums:function(arrylist,type_str){
        var str = "";
        var arr = [];
        for(var i=0;i<arrylist.length;i++){
            if(i<10){
                arr.push(arrylist[i]);
            }
        }
        str = arr.join(",");
        if(arrylist.length>10){
            str += "..."; 
        }
        if(arrylist.length>0){
            str = type_str + str + ' 未做'
        }
        return str;
    }
}