import Vue from 'vue'
import App from './App.vue'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import router from "./router.js"
import store from "./store"
import axios from 'axios'
import VueAxios from 'vue-axios'
import 'normalize.css'
import '@/assets/fonts/iconfont.css'
import 'font-awesome/css/font-awesome.min.css'
import '@/assets/css/bootstrap.min.css'
import * as echarts from 'echarts'
// import vuedraggable from 'vuedraggable'

import {
    Message, Loading
} from 'element-ui'

Vue.prototype.$echarts = echarts
Vue.config.productionTip = false
Vue.use(ElementUI)
Vue.use(VueAxios, axios)

let loading = null
let requestCount = 0
let messageCount = 0;

// 显示loading
function showLoading() {
    //console.log("showLoading");
    if (requestCount === 0) {
        /*loading = Message({
          message: '加载中...',
          duration:0
        });*/
        let options = {
            lock: true,
            text: '加载中...',
            spinner: 'el-icon-loading',
            background: 'rgba(0, 0, 0, 0.7)',
        };
        loading = Loading.service(options);
    }
    requestCount++
}

// 隐藏loading
function hideLoading() {
    if (requestCount > 0) {
        requestCount--
    }
    if (loading && requestCount === 0) {
        loading.close()
    }
}

// 添加请求拦截器
axios.interceptors.request.use((config) => {
    //console.log("添加请求拦截器成功");
    // 添加header头的token
    let token = window.sessionStorage.getItem('token')
    if (config.token === true) {
        config.headers['token'] = token
    }
    // 显示loading
    if (config.loading === true) {
        showLoading()
    }
    // 在发送请求之前做些什么
    return config;
}, (error) => {
    // 隐藏loading
    hideLoading()
    // 对请求错误做些什么
    return Promise.reject(error);
});

// 添加响应拦截器
axios.interceptors.response.use((response) => {
    //console.log('响应拦截器 成功');
    if (response.data.status_code == 201) {
        if (messageCount == 0) {
            Message({
                message: '您已经在另一台电脑上登陆!!!',
                duration: 3000,
                type: 'warning',
                offset: 200
            });
            messageCount++;
        }
        window.sessionStorage.clear()
        window.location.href = "/#/login";
        return;
    }

    let authorization = response.headers.authorization
    //console.log("response.headers:",authorization);
    if (authorization != undefined) {
        let authorization_arr = authorization.split(" ");
        //console.log("authorization_arr",authorization_arr);
        if (authorization_arr.length > 1) {
            if (authorization_arr[0] == 'Bearer') {
                store.commit('refreshToken', authorization_arr[1]);
            }
        }
    }

    // 隐藏loading
    hideLoading()
    // 对响应数据做点什么
    return response;
}, (err) => {
    // 全局错误提示
    if (err.response.data.status_code == 500) {
        //console.log("登陆超时");
        Message.error("登陆超时,请重新登陆")
        window.sessionStorage.clear()
        window.location.href = "/#/login";
        return;
    } else if (err.response.data.status_code == 401) {
        Message.error("登陆超时,请重新登陆")
        window.sessionStorage.clear()
        window.location.href = "/#/login";
        return;
    }
    /*if(err.response.data.status_code == 501){
        Message.error(err.response.data.msg)
        return;
    }*/

    if (err.response && err.response.data && err.response.data.errorCode) {
        Message.error(err.response.data.msg)
    }
    // 隐藏loading
    hideLoading()
    // 对响应错误做点什么
    return Promise.reject(err);
});

import $conf from "./common/config/config.js"

Vue.prototype.$conf = $conf
import $utils from "./utils/utils.js"

Vue.prototype.$utils = $utils
Vue.prototype.hideLoading = hideLoading
Vue.prototype.showLoading = showLoading;
// 注册一个全局自定义指令 `v-focus`
Vue.directive('focus', {
    bind: function (el) {
        //每当指令绑定到元素上的时候，会立即执行这个bind函数，只执行一次
        //注意 在每个函数中，第一个参数是el，表示被绑定了指令的那个元素，这个el是一个原生的JS对象，所以el有一些js的方法
        //在元素 刚绑定了指令的时候，还没有插入到DOM中去，这时候调用focus方法没有作用，因为一个元素只有插入DOM之后才能获取焦点
        el.focus()//这个方法不合适 虽然不报错 但是没效果，需要插入到DOM中
        //和样式相关的操作，一般都可以在bind中执行
    },
    // 当被绑定的元素插入到 DOM 中时……
    inserted: function (el) {
        // 聚焦元素
        el.focus()
    }
})

//console.log("main===================start")

new Vue({
    router,
    store,
    render: h => h(App),
}).$mount('#app')
