export default {
	state:{
        page_index: 1
	},
	getters:{
	},
	mutations:{		
        setPage (state,index) {            
            state.page_index = index;
            console.log(index);
        }
	},
	actions:{		
	}
}