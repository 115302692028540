export default {
	logo: 'ADMIN',
	navBar: {
		active: '0',
		list: [
		]
	},
	audio_url:"http://manage.xmy.xyz",
	//audio_url:"http://www.ying-dev.com",
	//audio_url:"http://manage.yingyu-dev.com",
	char_type:",!?.，！？。、:：",
	//storage_url:'http://api.yingyu-dev.com/storage',
	storage_url:'http://newapi.xmy.xyz/storage',
}
